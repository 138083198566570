<template>
  <div>
    <div
      role="alert"
      aria-live="polite"
      aria-atomic="true"
      class="alert alert alert-custom alert-white alert-shadow fade show gutter-b alert-light searchzone"
      style="margin-top: -10px"
    >
      <div class="alert-icon">
        <span class=""
          ><i class="fas fa-search text-primary" style="font-size: 15px"></i>
        </span>
      </div>
      <div class="alert-text">
        <b-form-input
          class="form-control form-control-sm form-control-solid"
          type="text"
          placeholder="Search"
          v-model="search"
          @change="searc"
          @input="searc"
        />
      </div>
    </div>
    <template>
      <div class="row" v-if="search === ''">
        <div class="col-xl-3 text-center" v-for="zone in zones" :key="zone.id">
          <div
            class="card card-custom bg-white card-stretch gutter-b cardzone"
            v-b-modal.modal-1
            @click="setSelectedCity(zone.name_en, zone.price, zone.id,zone,zone.name_ar)"
          >
            <div class="card-body">
              <span class="svg-icon svg-icon-2x svg-icon-white">
                <i class="fas fa-tag text-dark" style="font-size: 30px"> </i>
              </span>
              <span
                class="card-title font-weight-bolder text-dark font-size-h2 mb-0 mt-6 d-block"
                >{{
                  isDistanceMode === true ? zone.displayed_price : zone.price
                }}
                JD</span
              >
              <span class="font-weight-bold font-size-h6 c" v-b-modal.modal-1>{{
                zone.name_en
              }}</span>
              |
              <span class="font-weight-bold font-size-h6 c" v-b-modal.modal-1>{{
                zone.name_ar
              }}</span>
            </div>
          </div>
          <div></div>
        </div>
        <b-modal
          id="modal-1"
          modal-class="modal"
          ref="my-modal"
          :title="$t('clan')=='en'?selectedCity +' ' + $t('Req'):$t('Req')+ ' ' + selectedCityAR "
          hide-footer
          :dir="$t('clan')=='en'?'ltr':'rtl'"
        >
          <div
          
            class="form-group"
            :class="{
              'form-group--error':
                submitStatus === 'ERROR' ? $v.order.name.$error : '',
            }"
          >
            <b-form-input
              type="text"
              class="form-control form-control-solid form-control-lg form__input"
              name="fname"
              :placeholder="$t('name')"
              value=""
              :class="{
                'form-input--error':
                  submitStatus === 'ERROR' ? $v.order.name.$error : '',
              }"
              v-model="order.name"
              :state="
                submitStatus === 'ERROR'
                  ? $v.order.name.$error === false
                    ? true
                    : false
                  : null
              "
            />
            <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
              {{$t('feild')}}
            </p>
          </div>

          <div
            class="form-group"
            :class="{
              'form-group--error':
                submitStatus === 'ERROR' ? $v.order.phone.$error : '',
            }"
          >
            <b-form-input
              type="number"
              class="form-control form-control-solid form-control-lg form__input"
              name="fname"
              onKeyPress="if(this.value.length==10) return false;"
              pattern="/^-?\d+\.?\d*$"
              :placeholder="$t('phone')"
              value=""
              :class="{
                'form-input--error':
                  submitStatus === 'ERROR' ? $v.order.phone.$error : '',
              }"
              v-model="order.phone"
              :state="
                submitStatus === 'ERROR'
                  ? $v.order.phone.$error === false
                    ? true
                    : false
                  : null
              "
            />
            <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
              {{$t('feild')}}
            </p>
            <div class="error" v-if="!$v.order.phone.minLength">
              Phone must have at least
              {{ $v.order.phone.$params.minLength.min }} numbers.
            </div>
          </div>

          <div class="form-group">
            <b-form-input
              type="number"
              class="form-control form-control-solid form-control-lg form__input"
              name="ammount"
              v-model="order.amount"
              :placeholder="$t('Amount')"
            />
          </div>

          <div class="form-group">
            <b-form-input
              type="text"
              class="form-control form-control-solid form-control-lg form__input"
              name="notes"
              v-model="order.notes"
              :placeholder="$t('notes')"
            />
          </div>

          <div class="form-group" >
            
            <datetime  v-model="dumbDate"   type='time' title='Select the time' :use12-hour="true" class="form-control form-control-solid form-control-lg form__input">
             
            </datetime>
             <span  >Selected time and date : {{showDate}}</span>
            

          </div>
<p class="typo__p text-danger" v-if="submitStatus === 'ERROR' && order.vehicle_type===''" :style="$t('clan')=='ar'?'text-align:right':''">
              Please select  vehicle type
            </p>
          <div class="radioContainer">
            
          <div :class="order.vehicle_type!=='0'?'radio':'radioSel'" @click="setType('0')">
            <img src="../../../../../public/media/types/car.png" style="width:70px"/>
          </div>
          <div :class="order.vehicle_type!=='1'?'radio':'radioSel'" @click="setType('1')">
            <img src="../../../../../public/media/types/scooter.png" style="width:70px"/>
          </div>
          </div>

          <div class="buttons">
            <b-button variant="white" class="mr-3" @click="hideModal"
              >{{$t('cancel')}}</b-button
            >
            <b-button variant="success" @click="submit"
              >{{$t('Create Request')}}</b-button
            >
          </div>
        </b-modal>
      </div>

      <div class="row" v-else>
        <div
          class="col-xl-3 text-center"
          v-for="zone in searched"
          :key="zone.id"
        >
          <div
            class="card card-custom bg-white card-stretch gutter-b cardzone"
            v-b-modal.modal-1
            @click="setSelectedCity(zone.name_en, zone.price, zone.id,zone,zone.name_ar)"
          >
            <div class="card-body">
              <span class="svg-icon svg-icon-2x svg-icon-white">
                <i class="fas fa-tag text-dark" style="font-size: 30px"> </i>
              </span>
              <span
                class="card-title font-weight-bolder text-dark font-size-h2 mb-0 mt-6 d-block"
                >{{
                  isDistanceMode === true ? zone.displayed_price : zone.price
                }}
                JD</span
              >
              <span class="font-weight-bold font-size-h6 c" v-b-modal.modal-1>{{
                zone.name_en
              }}</span>
              |
              <span class="font-weight-bold font-size-h6 c" v-b-modal.modal-1>{{
                zone.name_ar
              }}</span>
            </div>
          </div>
          <div></div>
        </div>
        <b-modal
          id="modal-1"
          modal-class="modal"
          ref="my-modal"
          :title="$t('clan')=='en'?selectedCity +' ' + $t('Req'):$t('Req')+ ' ' + selectedCityAR "
          hide-footer
          :dir="$t('clan')=='en'?'ltr':'rtl'"
        >
          <div
            class="form-group"
            :class="{
              'form-group--error':
                submitStatus === 'ERROR' ? $v.order.name.$error : '',
            }"
          >
            <b-form-input
              type="text"
              class="form-control form-control-solid form-control-lg form__input"
              name="fname"
              :placeholder="$t('name')"
              value=""
              :class="{
                'form-input--error':
                  submitStatus === 'ERROR' ? $v.order.name.$error : '',
              }"
              v-model="order.name"
              :state="
                submitStatus === 'ERROR'
                  ? $v.order.name.$error === false
                    ? true
                    : false
                  : null
              "
            />
            <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
              {{$t('feild')}}
            </p>
          </div>

          <div
            class="form-group"
            :class="{
              'form-group--error':
                submitStatus === 'ERROR' ? $v.order.phone.$error : '',
            }"
          >
            <b-form-input
              type="number"
              class="form-control form-control-solid form-control-lg form__input"
              name="fname"
              onKeyPress="if(this.value.length==10) return false;"
              pattern="/^-?\d+\.?\d*$"
              :placeholder="$t('phone')"
              value=""
              :class="{
                'form-input--error':
                  submitStatus === 'ERROR' ? $v.order.phone.$error : '',
              }"
              v-model="order.phone"
              :state="
                submitStatus === 'ERROR'
                  ? $v.order.phone.$error === false
                    ? true
                    : false
                  : null
              "
            />
             <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
              {{$t('feild')}}
            </p>
            <div class="error" v-if="!$v.order.phone.minLength">
              Phone must have at least
              {{ $v.order.phone.$params.minLength.min }} numbers.
            </div>
          </div>
          <div class="form-group">
            <b-form-input
              type="number"
              class="form-control form-control-solid form-control-lg form__input"
              name="ammount"
              v-model="order.amount"
              :placeholder="$t('Amount')"
            />
          </div>

          <div class="form-group">
            <b-form-input
              type="text"
              class="form-control form-control-solid form-control-lg form__input"
              name="notes"
              v-model="order.notes"
              :placeholder="$t('Notes')"
            />
          </div>

          <div class="form-group">
            <!--<b-timepicker class="form-control form-control-solid form-control-lg form__input" 
            v-model="order.delivery_date" />-->
            <datetime  v-model="dumbDate"   type='time' title='Select the time' :use12-hour="true" class="form-control form-control-solid form-control-lg form__input">
             
            </datetime>
             <span  >Selected time and date : {{showDate}}</span>
          </div>
  <p class="typo__p text-danger" v-if="submitStatus === 'ERROR' && order.vehicle_type===''" :style="$t('clan')=='ar'?'text-align:right':''">
              Please select  vehicle type
            </p>
          <div class="radioContainer">
          <div :class="order.vehicle_type!=='0'?'radio':'radioSel'" @click="setType('0')">
            <img src="../../../../../public/media/types/car.png" style="width:70px"/>
          </div>
          <div :class="order.vehicle_type!=='1'?'radio':'radioSel'" @click="setType('1')">
            <img src="../../../../../public/media/types/scooter.png" style="width:70px"/>
          </div>
          </div>

          <div class="buttons">
            <b-button variant="white" class="mr-3" @click="hideModal"
              >{{$t('cancel')}}</b-button
            >
            <b-button variant="success" @click="submit"
              >{{$t('Create Request')}}</b-button
            >
          </div>
        </b-modal>
      </div>
    </template>
  </div>
</template>
 

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { required, minLength } from "vuelidate/lib/validators";
//import image from '../../../../../public/media/types/scooter.png'

import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      selectedCity: null,
      selectedCityAR:null,
      isDistanceMode: "",
      order: {
        client_id: "",
        zone_id: "",
        address_id: "",
        amount: 0,
        fees: "",
        customer_id: "",
        phone: "",
        name: "",
        email: "",
        notes:'',
        delivery_date:'',
        vehicle_type:''
      },
      dumbDate:'',
      search: "",
      showDate:new Date(),

      submitStatus: null,
      zones: null,
      searched: null,
      user: "",
      ammount: "0.0",
    };
  },
  computed: {
    ...mapGetters({ clients: "clients/table" }),
  },
  validations: {
    order: {
      name: {
        required,
      },
      phone: {
        required,
        minLength: minLength(10),
      },
      vehicle_type:{
        required
      }
    },
  },
  methods: {
    setType(el){
      this.order.vehicle_type = el

    },
    searc() {
      var s = [];
      for (var b = 0; b < this.zones.length; b++) {
        //var x = this.search.split(' ')[0]
        var se = this.search.split(' ')
        if(se[1] ===""){
          this.search = se[0]
        }
        if (
          this.zones[b].name_en.includes(this.search) === true ||
          this.zones[b].name_en.toLowerCase().includes(this.search) ===
            true ||
          this.zones[b].name_ar.includes(this.search)
        ) {
          s.push(this.zones[b]);
        }
      }
      this.searched = s;
    },
    setSelectedCity: function (value, price, zid,zone,vAR) {
      this.selectedCity = value;
      this.selectedCityAR = vAR;
      this.order.fees = parseFloat(price);
      this.order.zone_id = zid;
      this.order.client_id = this.data.id
      //console.log(this.data)
      //console.log(zone)
      //console.log(this.data.user_id)
      //console.log(zone)
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      this.submitStatus = null;
      (this.name = null), (this.phone = null);
    },
    async submit() {
      //console.log(new Date(this.order.delivery_date))
      //this.order.delivery_date = new Date(this.order.delivery_date).toString()
      
      const monthNames = ["1", "2", "3", "4", "5", "6",
  "7", "8", "9", "10", "11", "12"
];
      var date = new Date(this.dumbDate)
      this.order.delivery_date = date.getFullYear() + '-' + monthNames[date.getUTCMonth()] +'-' + date.getUTCDate() + ' ' + date.getHours()+':'+date.getUTCMinutes()+':'+date.getUTCSeconds()
      //console.log(this.order)

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.$store
          .dispatch("orders/create", this.order)
          .then(() => {
            Swal.fire({
              title: "",
              text: "The Order has been created successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          })
          .catch(() => {});

        this.$refs["my-modal"].hide();
        this.submitStatus = null;
        (this.name = null), (this.phone = null);

        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
  },
  updated(){
    const monthNames = ["1", "2", "3", "4", "5", "6",
  "7", "8", "9", "10", "11", "12"
];
var date = new Date(this.dumbDate)
//console.log(date)
var pmAm =''
var chours=date.getHours()
var horus=''

     // console.log(chours<12)
      if(chours>12){
        horus = chours - 12
        pmAm = 'PM'
      }else{
        horus = chours
        pmAm ='AM'
      }
      if(chours ==12){
        pmAm ='PM'
      }
      if(chours ==0){
        horus=12
        pmAm ='AM'
      }

       this.showDate = date.getFullYear() + '-' + monthNames[date.getUTCMonth()] +'-' + date.getUTCDate() + ' '+ horus+':' +(date.getUTCMinutes()<10?'0':'')+ date.getUTCMinutes() +' '+ pmAm


},

  async mounted() {
    const monthNames = ["1", "2", "3", "4", "5", "6",
  "7", "8", "9", "10", "11", "12"
];
 var date = new Date()

var pmAm =''
var chours=date.getHours()
var horus=''

     // console.log(chours<12)
      if(chours>12){
        horus = chours - 12
        pmAm = 'PM'
      }else{
        horus = chours
        pmAm ='AM'
      }
      if(chours ==12){
        pmAm ='PM'
      }
      if(chours ==0){
        horus=12
        pmAm ='AM'
      }
    //var user = JSON.parse(localStorage.getItem('client'))
    //console.log(user)
    //initialize order time
   
    //console.log(date.toString().split(' GMT')[0])
    //var ctime = date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()
    //var ctime = date.toString().split(' GMT')[0]
    var ctime = date.toISOString()
    //console.log(date.toISOString())
    //var t = date
    this.dumbDate = ctime
    this.showDate = date.getFullYear() + '-' + monthNames[date.getUTCMonth()] +'-' + date.getUTCDate() + ' '+ horus+':' +(date.getUTCMinutes()<10?'0':'')+ date.getUTCMinutes() +' '+ pmAm
    //console.log(this.dumbDate)
    //this.order.delivery_date = ctime
    //-----------------------
    //await this.$store.dispatch("clients/loadAll");
    this.data = JSON.parse(localStorage.getItem('client'))
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Delivery Requests", route: "" },
    ]);
    //console.log(this.data.data)s
    var client = this.data

    /*this.user = await this.$store.dispatch(VERIFY_AUTH);
    var cl = this.clients.items.filter((s) => s.name === this.user.data.name);
    this.order.client_id = cl[0].id;*/

    this.zones = await this.$store.dispatch("clients/getClientZone", client);
    //this.zones = client.zones
    //console.log(client.zones)

    if (client.pricing_mode === "distance_mode") {
      this.isDistanceMode = true;
    }
  },
};
</script>

<style scoped>
.c:hover {
  color: rgb(0, 132, 255);
}
.c:focus {
  outline: none;
}

.buttons {
  float: right;
}

.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.error {
  color: rgb(235, 128, 41);
}

.cardzone:focus {
  outline: none;
}

.cardzone:hover {
  color: rgb(0, 132, 255);
}
.radioContainer{
  
  display: flex;
  justify-content: center;
}
.radio{
  padding: 20px;
  border: 2px solid rgba(128, 128, 128, 0.164);
 margin-left: 10px;
 margin-bottom: 30px;
 border-radius: 20px;
 
}
.radio:hover{
  cursor: pointer;
}
.radioSel{
  padding: 20px;
  border: 2px solid red;
 margin-left: 10px;
 margin-bottom: 30px;
 border-radius: 20px;
 
}
</style>
